import React from 'react'
import Page from 'components/SimplePage'
import SubHeader from 'components/PageHeader'
import {contactColor} from 'styles/colors'
import Text from 'components/Text'

export default () =>
  <Page>
    <SubHeader color={contactColor}>Mitglied werden</SubHeader>
    <Text>
      Du willst den Moving Colours nicht nur zuhören, sondern auch selbst mitsingen? Wir freuen uns
      über dein Interesse und begrüßen gerne talentierte Sänger*innen in unserem Ensemble!
    </Text>
    <Text>
      Wir proben jeden Mittwoch von 19:30 - 22:00 in der Zunftwirtschaft in Moabit.
    </Text>
    <Text>
      Wenn du bei uns mitmachen willst, solltest du am Besten ein paar Voraussetzungen mitbringen.
      Neben musikalischen Fähigkeiten in Form von Notenkenntnissen und stimmlicher Sicherheit, die
      du im Optimalfall schon in anderen Chören unter Beweis gestellt hast, ist auch ein gewisses
      Engagement neben den offiziellen Proben gewünscht.
      Du solltest bereit sein, auch in deiner Freizeit eigenständig zu üben und nicht davor
      zurückscheuen, auch kleinere Organisationsaufgaben zu übernehmen, so dass alle zu einem
      gut funktionierenden Ganzen beitragen.
    </Text>
    <Text>
      Wenn du zusätzlich auch noch motiviert bist, an jährlichen Chorwochenenden, vereinzelten
      Sonderproben und natürlich an Konzertauftritten teilzunehmen, freuen wir uns darauf, dich bei
      einer unserer Proben kennenzulernen.
    </Text>
    <Text>
      Sollte es dir bei uns gefallen, wirst du nach ein paar Proben ein kleines Vorsingen mit einem
      Stück deiner Wahl bei unserem Chorleiter absolvieren. Ein paar Wochen später folgt ein
      Kleingruppensingen mit einem Stück aus unserem Repertoire. Im Anschluss daran entscheiden
      du und wir, ob wir gut zusammenpassen. Wir werden dir dabei gern zur Seite stehen und dich
      bei jedem Schritt bestmöglich unterstützen.
    </Text>
    <Text>
      Haben wir dein Interesse geweckt? Bitte schreibe uns eine Nachricht an interesse@moving-
      colours und teile uns mit, in welcher Stimmgruppe du mitsingen möchtest und welche
      Gesangserfahrung du bereits mitbringst. Wir werden uns dann schnellstmöglich bei dir melden.
    </Text>
    <Text>
      Aktuell suchen wir vor allem Verstärkung in den Männerstimmen, sowie im Sopran I. Wir sind
      jedoch offen für neue Mitglieder in allen Stimmgruppen und freuen uns auf dich!
    </Text>
  </Page>
